/*
 * @Author: Elon-Ysuhan
 * @LastEditors: Chen
 * @description: 重写Cesium对象
 * @Date: 2022-05-05 10:24:01
 * @LastEditTime: 2025-01-07 09:21:01
 */
//  The URL on your server where CesiumJS's static files are hosted.

/**
 * new Cesium.WebMapTileServiceImageryProvider({
        //影像底图
        url: "http://t{s}.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=262a5096a95bd3b84c7bcbd63fbbb572",
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        layer: "tdtImgLayer",
        style: "default",
        format: "image/jpeg",

        tileMatrixSetID: "GoogleMapsCompatible",//使用谷歌的瓦片切片方式
    })
 */
import * as Cesium from "cesium";
import { Cartesian3 } from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { headActionCreators } from "../../store/head";
import store from "../../utils/store";
import axios from "axios";
// window['CESIUM_BASE_URL'] = './';

export default class CesiumInit {
  viewer: any;
  Cesium: any;
  $htmlContainer: any;
  entity: Cesium.Entity = undefined;
  constructor() {
    this.Cesium = Cesium;
  }
  /**
   * @description: 重写Viewer对象
   * @param {*} el
   * @return {* Viewer}
   */
  Viewers = (el) => {
    const imageryProvider = new Cesium.ArcGisMapServerImageryProvider({
      url: "https://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer",
      maximumLevel: 13,
    });
    imageryProvider.defaultBrightness = 0.4;
    imageryProvider.defaultSaturation = -0.009;
    Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5NjE0NTVlNS0wN2Q2LTRiZjgtYmQ1Yi04MDBjNDgyNzQ1YjIiLCJpZCI6NDA3NDUsImlhdCI6MTcxMDc1MDU5N30.Z0ZC5gVBNrP-ZnTZPSw0AjNXfhymEgY9C4x_foLtUDM';
    this.viewer = new Cesium.Viewer(el, {      
      infoBox: false,
      selectionIndicator: false,
      // navigation: false,
      animation: false,
      timeline: false,
      baseLayerPicker: false,
      geocoder: false,
      homeButton: false,
      sceneModePicker: false,
      navigationHelpButton: false,
      shouldAnimate: true,
      fullscreenButton: false,
      // imageryProvider,
      contextOptions: {
        //cesium状态下允许canvas转图片convertToImage
        webgl: {
          alpha: true,
          depth: false,
          stencil: true,
          antialias: true,
          premultipliedAlpha: true,
          preserveDrawingBuffer: true,
          failIfMajorPerformanceCaveat: true,
        },
        allowTextureFilterAnisotropic: true,
        requestWebgl2: true,
      },
    });

    this.viewer.scene.requestRenderMode = true;

    new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas).setInputAction(
      (e) => {
        let feature = this.viewer.scene.pick(e.endPosition);
        if (this.entity) {
          //@ts-ignore
          if (this.entity.static) {
            //@ts-ignore
            this.entity.polygon.material = this.entity.static;
          }
        }
        if (!feature || !feature.id || feature.id.id === "COUNTYBORDER") {
          this.hide();
          return; // 没有拾取到对象 直接返回 不做任何操作
        }
        this.entity = feature.id;
        if (this.entity) {
          //@ts-ignore
          if (this.entity.active) {
            //@ts-ignore
            this.entity.polygon.material = this.entity.active;
          }
        }
        let pickPosition = this.viewer.scene.pickPosition(e.endPosition);
        if (!pickPosition) {
          pickPosition = this.viewer.scene.camera.pickEllipsoid(
            e.endPosition,
            this.viewer.scene.globe.ellipsoid
          );
        }
        if (!pickPosition) return;
        if (
          feature.id.type === "Town" ||
          feature.id.type === "PUTIAN_BILLBOARD_GJ" ||
          feature.id.type === "wushui" ||
          feature.id.type === "jiehu" ||
          feature.id.type === "yijiehu_polygon" ||
          feature.id.type === "PUTIAN_FuZhu" ||
          feature.id.type === "Village"
        ) {
          this.show(feature.id.name, pickPosition);
          if (feature.id.type === "Town") {
            // this.viewer.scene.entities.add({
            // });
          }
          if (feature.id.type === "Village") {
            this.show(feature.id.properties.XZQMC._value, pickPosition);
          } else {
            this.show(feature.id.name, pickPosition);
          }
        }
      },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE
    );

    new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas).setInputAction(
      (e) => {
        const screenPosition = new Cesium.Cartesian2(e.position.x, e.position.y);
        const cartesian = this.viewer.camera.pickEllipsoid(screenPosition);
        if (cartesian) {
          const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          const longitude = Cesium.Math.toDegrees(cartographic.longitude);
          const latitude = Cesium.Math.toDegrees(cartographic.latitude);
          console.log( `${longitude},${latitude}`);
      } 
        
        let feature = this.viewer.scene.pick(e.position);
        if (!feature || !feature.id || feature.id.id === "COUNTYBORDER") {
          this.hide();
          return; // 没有拾取到对象 直接返回 不做任何操作
        }
        if (feature.id.polygon) {
          if (feature.id.type === "Town" || feature.id.type === "Village") {
            this.viewer.flyTo(feature.id);
            switch (feature.id.type) {
              case "Town":
                store.dispatch(
                  headActionCreators.setMultipleData({
                    suffixKey: feature.id.properties.Id._value,
                    areaName: "",
                  })
                );
                break;
              case "Village":
                axios
                  .get(
                    `/admin_area/get_by_code/${feature.id.properties.XZQDM._value}`
                  )
                  .then((res: any) => {
                    if (res.code === 200) {
                      store.dispatch(
                        headActionCreators.setMultipleData({
                          suffixKey: res.data.id,
                          areaName: `${res.data.parentName}-${res.data.name}`,
                        })
                      );
                    }
                  });

                break;
            }
            store.dispatch(headActionCreators.setDrawerKey(0));
            store.dispatch(headActionCreators.setModuleKey("0"));
          }
        }
      },
      Cesium.ScreenSpaceEventType.LEFT_CLICK
    );

    this.setInitSence();
    /**
     * 叠加影像
     */
    this.addLayer();

    return this.viewer;
  };
  initDom() {
    this.$htmlContainer = document.createElement("div");
    this.$htmlContainer.classList.add("tooltip-container");
    this.viewer.cesiumWidget.container.appendChild(this.$htmlContainer);
  }

  show(tooltip, position) {
    if (!this.$htmlContainer) {
      this.initDom();
    }
    this.$htmlContainer.style.display = "block";
    this.$htmlContainer.innerHTML = tooltip;
    this.setPosition(position);
  }

  setPosition(position) {
    const canvasHeight = this.viewer.scene.canvas.height;
    const windowPosition = new Cesium.Cartesian2();
    Cesium.SceneTransforms.wgs84ToWindowCoordinates(
      this.viewer.scene,
      position,
      windowPosition
    );
    this.$htmlContainer.style.bottom =
      canvasHeight - windowPosition.y + 20 + "px";
    const elWidth = this.$htmlContainer.offsetWidth;
    this.$htmlContainer.style.left = windowPosition.x - elWidth / 2 + "px";
  }
  hide() {
    if (!this.$htmlContainer) {
      return;
    }
    this.$htmlContainer.style.display = "none";
  }
  /**
   * @description: 叠加天地图地图影像
   * @param {*}
   * @return {*}
   */
  addLayer = () => {
    // Access the CartoDB Positron basemap, which uses an OpenStreetMap-like tiling scheme.
    const positron = new Cesium.UrlTemplateImageryProvider({
      url: "https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&tk=726529ec02bbc5a6e7ec9da73c0e4481&TileCol={x}&TileRow={y}&TileMatrix={z}",
      subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
      maximumLevel: 18,
    });
    this.viewer.imageryLayers.addImageryProvider(positron, 1);
    this.viewer.imageryLayers.get(1).show = false;

    const sutang = new Cesium.UrlTemplateImageryProvider({
      url: "https://dredge-3.oss-cn-shanghai.aliyuncs.com/tiles/st/{z}/{x}/{y}.png",
      maximumLevel: 21,
      rectangle: Cesium.Rectangle.fromDegrees(
        119.099313390119,
        25.296460838523,
        119.135412338683,
        25.3129544233835
      ),
      minimumLevel: 15,
    });
    this.viewer.imageryLayers.addImageryProvider(sutang, 2);
    this.viewer.imageryLayers.get(2).show = false;

    const xx = new Cesium.UrlTemplateImageryProvider({
      url: "https://dredge-3.oss-cn-shanghai.aliyuncs.com/tiles/xx/{z}/{x}/{y}.png",
      // rectangle: Cesium.Rectangle.fromDegrees(119.070555867615, 25.3532651402981, 119.092088949098, 25.3327349635551),
    });
    this.viewer.imageryLayers.addImageryProvider(xx, 3);
    this.viewer.imageryLayers.get(3).show = false;
  };
  /**
   * @description: 创建颜色，返回Cesium里面的颜色
   * @param {*} a 红
   * @param {*} b 黄
   * @param {*} c 蓝
   * @param {*} d 透明通道
   * @return {*} Color
   */
  Color = (a, b, c, d) => {
    return new Cesium.Color(a / 255, b / 255, c / 255, d);
  };
  /**
   * @description: 创建颜色，返回Cesium里面的颜色
   * @param {* color} css颜色 #2343
   * @return {*} Color
   */
  CssColor = (color) => {
    return Cesium.Color.fromCssColorString(color);
  };
  /**
   * @description: 初始化界面部分参数比如地图背景颜色、缩放层级限制、隐藏logo、关闭光源等等
   * @param {*} viewer
   * @return {*}
   */
  setInitSence = () => {
    // 是否开启抗锯齿
    // this.viewer.scene.fxaa = true
    // this.viewer.scene.postProcessStages.fxaa.enabled = true;
    // 关闭地表大气层，默认是 true
    this.viewer.scene.globe.showGroundAtmosphere = false;
    /**
     * 放大缩小层级设置
     */
    this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 10;
    this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 150000;

    // 隐藏logo
    this.viewer.cesiumWidget.creditContainer.style.display = "none";

    this.viewer.scene.screenSpaceCameraController.enableCollisionDetection =
      true;
    // 场景变为2d
    this.viewer.scene.mode = Cesium.SceneMode.SCENE2D;

    this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
  };
}

/**
 * @description: 创建cesium坐标系
 * @param {*} p
 * @return {*}
 */
export const c3 = (p) => {
  return Cartesian3.fromDegrees(p[0], p[1]);
};
